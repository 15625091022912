// React
import React, { useState, useEffect, createContext } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import _get from 'lodash.get';

// Utilities
import { localize } from 'utilities';
import { useTheme, useCart } from 'hooks';

// Components
import SEO from 'components/seo';
import Steps from 'components/_subscription/steps';
import Title from 'components/_subscription/title';
import Subscription from 'components/subscription';

// Context
const SubscriptionContext = createContext();
export { SubscriptionContext };

const SubscriptionPageComponent = ({ pageContext, content, location }) => {
    const { locale, labels, allSwellProducts } = pageContext;
    const {
        seoMetaTags,
        filterCoffees,
        espressos,
        detailsCateredFilterCoffee,
        detailsCateredEspresso,
    } = content;

    // Hook: Cart
    const { addSubscriptionItem } = useCart();

    // Catch incoming subscriptions from products or banners
    useEffect(() => {
        // Are there anything predefined?
        const predefined = _get(location, 'state.subscription', null);

        if (predefined) {
            // Did the wizard start?
            setWizardStarted(predefined.started);

            // Is it filter coffee or espressos?
            const category =
                predefined.model === 'FILTER_COFFEE'
                    ? filterCoffees
                    : espressos;

            // Look for subscription product with the same id (predefined id)
            const [subscriptionProduct] = category.filter(
                item => item.baseProduct[0].id === predefined.id
            );

            // Does the predefined id exist in subscription category
            if (subscriptionProduct) {
                setTimeout(() => {
                    // Set category
                    setCategory(predefined.model);

                    // Set type
                    setType(['DECIDE', subscriptionProduct]);

                    // Move forward
                    setCurrentStep(currentStep + 2);
                }, 500);
            }
        }
    }, [location]);

    // Set theme
    const theme = useTheme(s.color('white'));

    // Subscription state
    const [wizardStarted, setWizardStarted] = useState(false);
    const [category, setCategory] = useState(null);
    const [type, setType] = useState(null);
    const [period, setPeriod] = useState(null);
    const [shippingLocation, setShippingLocation] = useState(null);
    const [added, setAdded] = useState(false);
    const [loading, setLoading] = useState(false);

    // Which products to show in step 2
    const [products, setProducts] = useState([]);
    useEffect(() => {
        switch (category) {
            case 'FILTER_COFFEE':
                setProducts(
                    filterCoffees.filter(filterCoffee => filterCoffee.active)
                );
                break;
            case 'ESPRESSO':
                setProducts(espressos.filter(espresso => espresso.active));
                break;
        }
    }, [category]);

    // Effect for visited steps
    useEffect(() => {
        // Expand visited
        setVisitedSteps([category, type, period].filter(item => item).length);
    }, [category, type, period]);

    // Steps
    const [visitedSteps, setVisitedSteps] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);

    // Selected Product
    const [selectedProduct, setSelectedProduct] = useState(null);
    useEffect(() => {
        if (category && type && period && shippingLocation) {
            // Switch on type
            switch (type[0]) {
                case 'DECIDE':
                    // Get details for decided upon coffee
                    const detailsCategoryDecide = JSON.parse(type[1].details)[
                        period[0]
                    ][shippingLocation];

                    // Set product
                    setSelectedProduct({
                        category,
                        type,
                        period,
                        name: `${type[1].baseProduct[0].name}, ${
                            JSON.parse(content.labels).PRODUCT_NAMES[period[0]]
                        }`,
                        price: detailsCategoryDecide.PRICE,
                        sku: detailsCategoryDecide.SKU,
                    });
                    break;
                case 'SURPRISE':
                    const detailsCategorySuprise = JSON.parse(
                        category === 'FILTER_COFFEE'
                            ? detailsCateredFilterCoffee
                            : detailsCateredEspresso
                    )[period[0]];

                    setSelectedProduct({
                        category,
                        type,
                        period,
                        name: detailsCategorySuprise.NAME,
                        price: detailsCategorySuprise[shippingLocation].PRICE,
                        sku: detailsCategorySuprise[shippingLocation].SKU,
                    });
                    break;
            }
        }
    }, [period, type, category, shippingLocation]);

    // Add to basket
    const addToBasket = () => {
        const { category, type, period, name, sku } = selectedProduct;

        // Start loading
        setLoading(true);

        console.log({
            id: allSwellProducts[sku].id,
            planId: allSwellProducts[sku].planId,
            metadata: {
                name,
                category,
                type,
                period,
                // Simulate model from DatoCMS for basket behaviour etc.
                model: { apiKey: 'SUBSCRIPTION' },
            },
        });

        // Add subscription
        addSubscriptionItem({
            id: allSwellProducts[sku].id,
            planId: allSwellProducts[sku].planId,
            metadata: {
                name,
                category,
                type,
                period,
                // Simulate model from DatoCMS for basket behaviour etc.
                model: { apiKey: 'SUBSCRIPTION' },
            },
        }).then(() => {
            setAdded(true);
            setLoading(false);
        });
    };

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />

                {wizardStarted ? (
                    <ContentWrapper>
                        <SubscriptionContext.Provider
                            value={{
                                theme,
                                products,
                                setCurrentStep,
                                currentStep,
                                visitedSteps,
                                added,
                                category,
                                currentStep,
                                labels: {
                                    ...JSON.parse(content.labels),
                                    ...labels,
                                },
                                locale,
                                period,
                                selectedProduct,
                                setCategory,
                                setCurrentStep,
                                setPeriod,
                                setType,
                                type,
                                added,
                                addToBasket,
                                shippingLocation,
                                setShippingLocation,
                                loading,
                            }}>
                            <Title />
                            <Steps />
                        </SubscriptionContext.Provider>
                    </ContentWrapper>
                ) : (
                    <Subscription
                        {...{
                            pageContext,
                            location,
                            type: 'page',
                            onClick() {
                                setWizardStarted(true);
                            },
                        }}
                    />
                )}
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    position: relative;
    ${s.responsive.property('padding-top', {
        0: 80,
        30: 120,
    })};

    ${s.responsive.property('min-height', {
        0: 'calc(100vh + 80px)',
        30: 'calc(100vh + 120px)',
    })};

    display: flex;
    flex-direction: column;
`;

const NamedSubscriptionPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageSubscription, allDatoCmsCountry } =
        useStaticQuery(graphql`
            query {
                allDatoCmsCountry(sort: { fields: [name], order: ASC }) {
                    edges {
                        node {
                            name
                            locale
                            id
                            code
                        }
                    }
                }
                allDatoCmsPageSubscription {
                    edges {
                        node {
                            id
                            locale
                            slug
                            countryShippingRules {
                                country {
                                    name
                                    code
                                }
                                label
                                price
                            }
                            filterCoffees {
                                id
                                active
                                details
                                baseProduct {
                                    ... on DatoCmsFilterCoffee {
                                        id
                                        name
                                        model {
                                            name
                                        }
                                        thumbnail {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyDatoCmsFluid_noBase64
                                            }
                                        }
                                    }
                                }
                            }
                            espressos {
                                id
                                active
                                details
                                baseProduct {
                                    ... on DatoCmsEspresso {
                                        id
                                        name
                                        model {
                                            name
                                        }
                                        thumbnail {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyDatoCmsFluid_noBase64
                                            }
                                        }
                                    }
                                }
                            }
                            detailsCateredFilterCoffee
                            detailsCateredEspresso
                            labels
                            seoMetaTags {
                                ...SEO
                            }
                        }
                    }
                }
            }
        `);

    return (
        <SubscriptionPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageSubscription, locale),
                    countries: localize(allDatoCmsCountry, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedSubscriptionPageComponent;
