// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';

// Components
import Step1 from './components/step_1';
import Step2 from './components/step_2';
import Step3 from './components/step_3';
import Summary from './components/summary';

const StepsComponent = () => (
    <>
        <StepsWrapper>
            <Step1 step={0} />
            <Step2 step={1} />
            <Step3 step={2} />
            <Summary step={3} />
        </StepsWrapper>
    </>
);

const StepsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
`;
export default StepsComponent;
