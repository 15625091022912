// React
import React, { useState, useEffect, useContext } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages

// Utilities
import { lineBreaks } from 'utilities';
import { useSubscriptionDates } from 'hooks';

// Components
import Button from 'components/button';
import StepWrapper from '../stepWrapper';

// Context
import { SubscriptionContext } from 'src/templates/subscription';

const Step_3Component = ({ step }) => {
    // Get data from context
    const {
        labels,
        setPeriod,
        period,
        setCurrentStep,
        currentStep,
        locale,
        type,
    } = useContext(SubscriptionContext);

    // Hook: Subscription dates
    const { getNextDeliveryDay, getTrialPeriodInDays, formatDate, formatDay } =
        useSubscriptionDates();

    // Get step based states
    const [state, setState] = useState({
        active: currentStep === step,
        closest: currentStep + 1 === step || currentStep - 1 === step,
        previous: currentStep > step,
        next: currentStep < step,
    });
    useEffect(() => {
        setState({
            active: currentStep === step,
            closest: currentStep + 1 === step || currentStep - 1 === step,
            previous: currentStep > step,
            next: currentStep < step,
        });
    }, [currentStep]);

    const [shippingDate, setShippingDate] = useState(null);
    const [trialPeriod, setTrialPeriod] = useState(null);

    useEffect(() => {
        if (type && type.length > 0) {
            setShippingDate(getNextDeliveryDay(type[0]));
            setTrialPeriod(getTrialPeriodInDays(getNextDeliveryDay(type[0])));
        }
    }, [type]);

    return (
        <>
            <Steps {...{ state }}>
                <StepWrapper
                    {...{
                        minHeight: 200,
                        state,
                        stepText: (
                            <>
                                <h2>{labels.STEPS[step].OPTION_B.TITLE}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: lineBreaks(
                                            labels.STEPS[step].OPTION_B
                                                .DESCRIPTION
                                        ),
                                    }}
                                />
                            </>
                        ),
                        stepAction: (
                            <Shipping>
                                <p>
                                    {labels.COMMON.SHIPMENT}
                                    <span className="mb-12">
                                        {formatDate(shippingDate)} (
                                        {formatDay(
                                            shippingDate,
                                            locale
                                        ).toLowerCase()}
                                        )
                                    </span>
                                </p>
                                {period && period[0] === '4_WEEKS' ? (
                                    <Selected>
                                        {labels.COMMON.SELECTED}
                                    </Selected>
                                ) : (
                                    <Button
                                        {...{
                                            onClick() {
                                                setPeriod([
                                                    '4_WEEKS',
                                                    {
                                                        trialPeriod:
                                                            trialPeriod,
                                                        date: shippingDate.toISOString(),
                                                    },
                                                ]);
                                                setCurrentStep(currentStep + 1);
                                            },
                                            type: 'secondary',
                                            label: labels.COMMON.SELECT,
                                            theme: s.color('gray'),
                                        }}
                                    />
                                )}
                            </Shipping>
                        ),
                    }}
                />
                <StepWrapper
                    {...{
                        minHeight: 250,
                        state,
                        stepText: (
                            <>
                                <h2>{labels.STEPS[step].OPTION_A.TITLE}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: lineBreaks(
                                            labels.STEPS[step].OPTION_A
                                                .DESCRIPTION
                                        ),
                                    }}
                                />
                            </>
                        ),
                        stepAction: (
                            <Shipping>
                                <p>
                                    {labels.COMMON.SHIPMENT}
                                    <span className="mb-12">
                                        {formatDate(shippingDate)} (
                                        {formatDay(
                                            shippingDate,
                                            locale
                                        ).toLowerCase()}
                                        )
                                    </span>
                                </p>
                                {period && period[0] === '2_WEEKS' ? (
                                    <Selected>
                                        {labels.COMMON.SELECTED}
                                    </Selected>
                                ) : (
                                    <Button
                                        {...{
                                            onClick() {
                                                setPeriod([
                                                    '2_WEEKS',
                                                    {
                                                        trialPeriod:
                                                            trialPeriod,
                                                        date: shippingDate.toISOString(),
                                                    },
                                                ]);
                                                setCurrentStep(currentStep + 1);
                                            },
                                            type: 'secondary',
                                            label: labels.COMMON.SELECT,
                                            theme: s.color('green'),
                                        }}
                                    />
                                )}
                            </Shipping>
                        ),
                    }}
                />
            </Steps>
        </>
    );
};

const Selected = styled.div`
    ${s.fonts.style('h16')};
    ${s.responsive.property('padding', {
        0: '10px 30px',
        20: '15px 30px',
    })};
    ${s.grid.columns('width', {
        0: 2,
    })};
    display: inline-block;
    border: 1px solid transparent;
    text-align: center;
`;

const Steps = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    pointer-events: none;

    /* active */
    ${props =>
        props.state.active &&
        css`
            pointer-events: auto;
            position: relative;
        `}
`;

const Shipping = styled.div`
    display: flex;
    flex-direction: column;
    p {
        ${s.fonts.style('h15')};
        span {
            display: block;
        }
        text-align: right;
    }
    button,
    ${Selected} {
        margin-left: auto;
    }
`;

export default Step_3Component;
