// React
import React, { useEffect, useRef, useContext } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { scrollTo, scrollTop } from 'utilities';
import { useResponsive } from 'hooks';

// Components
import Arrow from 'components/arrow';

// Context
import { SubscriptionContext } from 'src/templates/subscription';

const TitleComponent = () => {
    // Get data from context
    const { theme, setCurrentStep, currentStep, labels, visitedSteps, added } =
        useContext(SubscriptionContext);

    // Refs
    const headingRef = useRef(null);

    // Navigate with arrows
    const navigate = direction => {
        switch (direction) {
            case 'left':
                setCurrentStep(currentStep - 1);
                break;
            case 'right':
                setCurrentStep(currentStep + 1);
                break;
        }
    };

    // Scroll to section
    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            // Scroll to top
            scrollTo(scrollTop(headingRef.current), () => {
                // Hide header
                setHideHeader(true);
            });
        }
    }, [currentStep]);

    // Show / hide header
    const [, setHideHeader] = useGlobal('hideHeader');

    // Breakpoints
    const breakpoint = useResponsive();

    return (
        <TitleWrapper ref={headingRef}>
            <div>
                <h2>
                    <Step
                        onClick={() =>
                            !added && visitedSteps + 1 > 0 && setCurrentStep(0)
                        }
                        breakpoint={breakpoint}
                        active={currentStep === 0}
                        visited={visitedSteps + 1 > 0}>
                        1. {labels.STEPS[0].NAME}
                    </Step>
                    <Step
                        onClick={() =>
                            !added && visitedSteps + 1 > 1 && setCurrentStep(1)
                        }
                        breakpoint={breakpoint}
                        active={currentStep === 1}
                        visited={visitedSteps + 1 > 1}>
                        2. {labels.STEPS[1].NAME}
                    </Step>
                    <Step
                        onClick={() =>
                            !added && visitedSteps + 1 > 2 && setCurrentStep(2)
                        }
                        breakpoint={breakpoint}
                        active={currentStep === 2}
                        visited={visitedSteps + 1 > 2}>
                        3. {labels.STEPS[2].NAME}
                    </Step>
                    <Step
                        onClick={() =>
                            !added && visitedSteps + 1 > 3 && setCurrentStep(3)
                        }
                        breakpoint={breakpoint}
                        active={currentStep === 3}
                        visited={visitedSteps + 1 > 3}>
                        4. {labels.SUMMARY.SUBTITLE}
                    </Step>
                </h2>
            </div>
            {breakpoint < 30 && (
                <Navigate>
                    <Left {...{ currentStep }}>
                        <Arrow
                            {...{
                                theme,
                                direction: 'left',
                                hover: true,
                                onClick: () => navigate('left'),
                            }}
                        />
                    </Left>
                    <Right
                        {...{
                            currentStep,
                            max: visitedSteps,
                        }}>
                        <Arrow
                            {...{
                                theme,
                                direction: 'right',
                                hover: true,
                                onClick: () => navigate('right'),
                            }}
                        />
                    </Right>
                </Navigate>
            )}
        </TitleWrapper>
    );
};

const TitleWrapper = styled.div`
    ${s.layout.fullWidthBackground(s.color('white').default())};
    padding-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    h2 {
        margin-bottom: 0;
    }

    ${s.grid.gutter('margin-bottom', {
        0: 2,
    })};
`;

const Step = styled.span`
    ${s.fonts.style('h19')};
    margin-right: 2em;
    opacity: 0.25;
    transition: opacity 200ms;

    ${props =>
        props.breakpoint < 30 &&
        !props.active &&
        css`
            display: none;
        `}

    ${props =>
        props.active &&
        css`
            opacity: 1;
        `}
    ${props =>
        props.visited &&
        css`
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        `}
`;

const Navigate = styled.div`
    display: flex;
    flex-wrap: nowrap;

    transform-origin: bottom right;

    ${s.responsive.property('transform', {
        0: 'scale(0.8)',
        20: 'scale(0.9)',
    })};

    margin-bottom: 5px;
`;

const Left = styled.div`
    pointer-events: auto;
    transition: opacity 400ms;
    ${s.responsive.property('margin-right', {
        0: '1.5em',
    })};

    ${props =>
        props.currentStep === 0 &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

const Right = styled.div`
    pointer-events: auto;
    transition: opacity 400ms;
    ${props =>
        props.currentStep === props.max &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

export default TitleComponent;
