// React
import React, { useState, useEffect, useContext } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages

// Utilities

// Components
import Button from 'components/button';
import StepWrapper from './../stepWrapper';

// Context
import { SubscriptionContext } from 'src/templates/subscription';

const Step_1Component = ({ step }) => {
    // Get data from context
    const {
        category,
        setCategory,
        setCurrentStep,
        currentStep,
        labels,
    } = useContext(SubscriptionContext);

    // Get step based states
    const [state, setState] = useState({
        active: currentStep === step,
        closest: currentStep + 1 === step || currentStep - 1 === step,
        previous: currentStep > step,
        next: currentStep < step,
    });
    useEffect(() => {
        setState({
            active: currentStep === step,
            closest: currentStep + 1 === step || currentStep - 1 === step,
            previous: currentStep > step,
            next: currentStep < step,
        });
    }, [currentStep]);

    return (
        <>
            <Steps {...{ state }}>
                <StepWrapper
                    {...{
                        minHeight: 200,
                        state,
                        stepText: (
                            <>
                                <h2>{labels.STEPS[step].OPTION_A.TITLE}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            labels.STEPS[step].OPTION_A
                                                .DESCRIPTION,
                                    }}
                                />
                            </>
                        ),
                        stepAction:
                            category === 'FILTER_COFFEE' ? (
                                <Selected>{labels.COMMON.SELECTED}</Selected>
                            ) : (
                                <Button
                                    {...{
                                        onClick() {
                                            setCategory('FILTER_COFFEE');
                                            setCurrentStep(currentStep + 1);
                                        },
                                        type: 'secondary',
                                        label: labels.COMMON.SELECT,
                                        theme: s.color('gray'),
                                    }}
                                />
                            ),
                    }}
                />
                <StepWrapper
                    {...{
                        minHeight: 200,
                        state,
                        stepText: (
                            <>
                                <h2>{labels.STEPS[step].OPTION_B.TITLE}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            labels.STEPS[step].OPTION_B
                                                .DESCRIPTION,
                                    }}
                                />
                            </>
                        ),
                        stepAction:
                            category === 'ESPRESSO' ? (
                                <Selected>{labels.COMMON.SELECTED}</Selected>
                            ) : (
                                <Button
                                    {...{
                                        onClick() {
                                            setCategory('ESPRESSO');
                                            setCurrentStep(currentStep + 1);
                                        },
                                        type: 'secondary',
                                        label: labels.COMMON.SELECT,
                                        theme: s.color('green'),
                                    }}
                                />
                            ),
                    }}
                />
            </Steps>
        </>
    );
};

const Selected = styled.div`
    ${s.fonts.style('h16')};
    ${s.responsive.property('padding', {
        0: '10px 30px',
        20: '15px 30px',
    })};
    ${s.grid.columns('width', {
        0: 2,
    })};
    text-align: center;
    border: 1px solid transparent;
`;

const Steps = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    pointer-events: none;

    /* active */
    ${props =>
        props.state.active &&
        css`
            pointer-events: auto;
            position: relative;
        `}
`;

export default Step_1Component;
