// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages

// Utilities

// Components

const StepWrapperComponent = ({
    state,
    stepText = null,
    stepAction = null,
    minHeight = 350,
}) => {
    return (
        <>
            <StepWrapper {...{ state, minHeight }}>
                <Inner {...{ state }}>
                    {stepText && <StepText>{stepText}</StepText>}
                    {stepAction && <StepAction>{stepAction}</StepAction>}
                </Inner>
            </StepWrapper>
        </>
    );
};

const Inner = styled.div`
    position: relative;
    z-index: 2;
    will-change: transform;
    opacity: 0;
    transform: translateX(100vw);
    transition: transform 0.4s ${s.easings.smooth.out}, opacity 0.4s 0.2s;

    display: flex;
    height: 100%;

    ${s.grid.gutter('padding-top', {
        0: 2,
        20: 3,
        30: 4,
    })};

    ${s.grid.gutter('padding-bottom', {
        0: 2,
    })};


    ${s.responsive.property('flex-direction', {
        0: 'column',
    })};

    h2 {
        ${s.fonts.style('h4')};
        display: inline-block;
        border-bottom: 1px solid ${s.color('green').textAlpha(0.8)};
        margin-bottom: 0.2em;
        padding-bottom: 0.2em;
        white-space: nowrap;
    }

    justify-content: space-between;

    /* active */
    ${props =>
        props.state.active &&
        css`
            transform: translateX(0vw);
            transition: transform 0.4s ${s.easings.smooth.out}, opacity 0.4s;
            opacity: 1;
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateX(-100vw);
        `}

    /* next */
    ${props =>
        props.state.next &&
        css`
            transform: translateX(100vw);
        `}`;

const StepWrapper = styled.div`
    flex-basis: 50%;
    flex-grow: 1;

    ${Inner} {
        ${s.layout.fullWidthBackground(s.color('green').default())}
        color: ${s.color('green').text()};
    }

    &:first-of-type {
        ${Inner} {
            ${s.layout.fullWidthBackground(s.color('gray').default())}
            color: ${s.color('gray').text()};

            h2 {
                border-color: ${s.color('gray').textAlpha(0.8)};
            }
        }
    }
`;

const StepText = styled.div`
    p {
        ${s.fonts.style('h15')};

        ${s.grid.columns('width', {
            0: 4,
            30: 6,
            40: 5,
        })};

        span {
            display: block;
            font-weight: 600;
        }
    }
`;

const StepAction = styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    button {
        ${s.grid.columns('width', {
            0: 2,
        })};
    }
`;

export default StepWrapperComponent;
